import React from 'react';
import banner from './video/last.mp4'
import './style.css'
import img1 from './image/img1.png';
import w1 from './image/ab1.png';
import w2 from './image/ab2.png';
import w5 from './image/ab3.png';
import w4 from './image/ab4.png';
import w3 from './image/ab5.png';
import w6 from './image/ab6.png';

const About = () => {
  
  return (
    <div>
      <div className="banner-container">
        <video className="banner-video" autoPlay loop muted>
          <source src={banner} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
      </div>
      <section className="about" >
        <div className="about-container">
          <div className='abo-info'>
            <h2 className="about-title">VETRI IT SYSTEMS...</h2>
          </div>
          <div className="about-row">
            <div className="about-col" >
              <h1 className='about-head'>Why us ?</h1>
              <div className='about-why-row'>
                <div className='about-why-col'>
                  <div className='about-why-card'>
                  <div className='about-why-img'>
                    <img src={w1}></img>
                  </div>
                  <div className='about-why-p'>
                     <p>Experienced & Young team</p>
                  </div>
                  </div>
                </div>
                <div className='about-why-col'>
                <div className='about-why-card'>
                  <div className='about-why-img'>
                    <img src={w2}></img>
                  </div>
                  <div className='about-why-p'>
                    <p>Worked with New startups</p>
                  </div>
                </div>
                </div>
                <div className='about-why-col'>
                <div className='about-why-card'>
                  <div className='about-why-img'>
                    <img src={w3}></img>
                  </div>
                  <div className='about-why-p'>
                    <p>Transparent Process</p>
                  </div>
                </div>
                </div>
                <div className='about-why-col'>
                <div className='about-why-card'>
                  <div className='about-why-img'>
                    <img src={w4}></img>
                  </div>
                  <div className='about-why-p'>
                    <p>Affordable price</p>
                  </div>
                </div>
                </div>
                <div className='about-why-col'>
                <div className='about-why-card'>
                  <div className='about-why-img'>
                    <img src={w5}></img>
                  </div>
                  <div className='about-why-p'>
                    <p>On-time Delivery</p>
                  </div>
                </div>
                </div>
                <div className='about-why-col'>
                <div className='about-why-card'>
                  <div className='about-why-img'>
                    <img src={w6}></img>
                  </div>
                  <div className='about-why-p'>
                    <p>Friendly Support</p>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <div className="about-col1" >
              <div className="about-img">
                <img className="img-ani" src={img1} alt="Animated Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
