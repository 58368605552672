import React from 'react';
import './posters.css';
import post from './image/im2.png'
import hydro from './image/hydro.png'
import builders from './image/builders.png'

const portfolioProjects = [
  { image: hydro },
  {image: builders }
];

const Portfolio = () => {
  return (
    <div>
      <section className="port-post">
        <div className="port-post-container">
          <div className="port-post-row">
            <div className="port-post-col1">
              <div>
                <p className="port-post-title">Posters Portfolio</p>
                <h1 className="port-post-head">Our Previous Works</h1>
                <p className="port-post-para">
                  Introducing Vetri It Systems’ Landing Page Showcase: Experience Our Engaging Designs! Witness the simplicity, effectiveness, and captivating layouts that convert visitors into enthusiastic customers. Explore our portfolio to see how we craft high-converting landing pages designed to drive your business forward.
                </p>
              </div>
            </div>
            <div className="port-post-col2">
              <div className="port-post-img">
                <img className="img-ani" src={post} alt="Portfolio" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="port-post-pro-container">
          <div className="port-post-pro-row">
            {portfolioProjects.map((project) => (
              <div key={project.id} className="port-post-pro-col">
                <div className="port-post-pro-card">
                  <img src={project.image} alt={project.name} className="project-img" />
                  <h1 className='pro-h1'>{project.name}</h1>
                  {/* <p className='pro-p'>{project.link}</p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Portfolio;