import React, { useEffect, useRef } from 'react';
import './Designport.css';
import poster1 from './image/po1.png';
import poster2 from './image/po2.png';
import poster3 from './image/po3.png';
import poster4 from './image/po4.png';
import poster5 from './image/po5.png';
import pdfGreen from './image/green.pdf';
import pdfFashion from './image/fashion.pdf';
import pdfLux from './image/lux.pdf';

import flyer1 from './image/fl1.png';
import flyer2 from './image/fl2.png';
import flyer3 from './image/fl4.png';
import flyer4 from './image/fl3.png';
import flyer5 from './image/fl5.png';

import brochure1 from './image/bg.jpeg';
import brochure2 from './image/bf.jpeg';
import brochure3 from './image/bl.jpeg';

const posters = [
  { src: poster1 },
  { src: poster2 },
  { src: poster3 },
  { src: poster4 },
  { src: poster5 }
];

const flyers = [
  { src: flyer1 },
  { src: flyer2 },
  { src: flyer3 },
  { src: flyer4 },
  { src: flyer5 }
];

const brochures = [
  { src: brochure1, pdf: pdfGreen },
  { src: brochure3, pdf: pdfLux },
  { src: brochure2, pdf: pdfFashion },
  { src: brochure1, pdf: pdfGreen },
  { src: brochure3, pdf: pdfLux },
  { src: brochure2, pdf: pdfFashion }
];

const Carousel = ({ slides, containerClassName, imageClassName, title }) => {
  const trackRef = useRef(null);

  useEffect(() => {
    const track = trackRef.current;
    const slideWidth = 450;
    const gapWidth = 10;
    const totalWidth = (slideWidth + gapWidth) * slides.length;
    const duplicatedTotalWidth = totalWidth * 2;
    const halfTotalWidth = duplicatedTotalWidth / 2;
    track.style.setProperty('--total-width', `${halfTotalWidth}px`);
    const baseDuration = 40;
    const baseWidth = 5000;
    const scrollDuration = (halfTotalWidth / baseWidth) * baseDuration;
    track.style.setProperty('--scroll-duration', `${scrollDuration}s`);
  }, [slides]);

  const handleSlideClick = (slide) => {
    if (slide.pdf) {
      window.open(slide.pdf, '_blank');
    }
  };

  return (
    <div className={`carousel-container ${containerClassName}`}>
      <h2 className="carousel-title">{title}</h2>
      <div className="carousel" aria-live="polite" aria-label="Image Carousel">
        <div className="carousel-track" ref={trackRef}>
          {slides.concat(slides).map((slide, index) => (
            <div className="carousel-slide" key={index} onClick={() => handleSlideClick(slide)}>
              <img className={imageClassName} src={slide.src} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const GraphicsDesign = () => {
  return (
    <section className="graphics-design-service" data-aos="fade-up">
      <Carousel slides={posters} containerClassName="posters-carousel" imageClassName="posters-slide-image" title="Sample Posters" />
      <Carousel slides={flyers} containerClassName="flyers-carousel" imageClassName="flyers-slide-image" title="Sample Flyers" />
      <Carousel slides={brochures} containerClassName="brochures-carousel" imageClassName="brochures-slide-image" title="Sample Brochures" />
    </section>
  );
};

export default GraphicsDesign;
