import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const Services = () => {
  return (
    <div>
      <h1>Services Page</h1>
      <ul>
        <li><Link to="type1">Service Type 1</Link></li>
        <li><Link to="type2">Service Type 2</Link></li>
        <li><Link to="type3">Service Type 3</Link></li>
        <li><Link to="type4">Service Type 4</Link></li>
      </ul>
      <Outlet />
    </div>
  );
};

export default Services;
