import React from 'react'
import Process from './process';
import Team from './team';
import SlideCarousel from './slideCarousel';
import About from './About';

const Home = () => {
  return (
    <div>
      <About/>
      <SlideCarousel/>
      <Process/>
      <Team/>
    </div>
  )
}
export default Home;
