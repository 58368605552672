import React, { useState } from 'react';
import axios from 'axios';
import './contactform.css';
import 'boxicons';
import enimg from './enquire.png'

const QuoteForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    services: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:5000/api/contact", formData);
      alert("Enquiry submitted and email sent to admin successfully");
    } catch (error) {
      console.error("Error submitting enquiry:", error.response ? error.response.data : error.message);
      alert("Error submitting enquiry");
    }
  };
  if (!isOpen) return null;
  return (
    <div className="overlay">
      <div className="popup">
        <button className="close-btn" onClick={onClose}>✖</button>
        <div className="form-section">
          <form className="form-container" onSubmit={handleSubmit}>        
            <div className="form-group">
              <label htmlFor="name">
                <box-icon type='solid' name='user'></box-icon> Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="email">
                <box-icon type='solid' name='envelope'></box-icon> Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="number">
                <box-icon type='solid' name='phone'></box-icon> Phone
              </label>
              <input
                type="text"
                name="number"
                value={formData.number}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="services">
                <box-icon type='solid' name='briefcase'></box-icon> Services
              </label>
              <select
                name="services"
                value={formData.services}
                onChange={handleChange}
                required
              >
                <option value="">Select a Service</option>
                <option value="Software Development">Software Development</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Web Development">Web Development</option>
                <option value="Software Testing">Software Testing</option>
                <option value="IT Consulting">IT Consulting</option>
                <option value="Digital Marketing">Digital Marketing</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="message">
                <box-icon type='solid' name='message-rounded-detail'></box-icon> Message
              </label>
              <input
                type="text"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>      
            <button type="submit">GET A QUOTE ➔</button>
          </form>
        </div>
        <div className="info-section">
          <h2>What's Next?</h2>
          <img src={enimg}></img>
          <div className="contact-options">
            <div className="contact-option">
              <box-icon type='solid' name='phone'></box-icon>
              <div>
                <p>Give us a call</p>
                <p><a href="tel:+918438558527" className="phone">+91 84385 58527</a></p>
              </div>
            </div>
            <div className="contact-option">
              <box-icon name='envelope' type='solid'></box-icon>
              <div>
                <p>Send an email</p>
                <p><a href="mailto:business@vetriit.com" className="mail">business@vetriit.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteForm;
