import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Digi.css';
import di1 from './image/di1.jpg';
import di2 from './image/di2.jpg';
import di3 from './image/di3.jpg';
import di4 from './image/d14.png';
import di5 from './image/di4.png';
import di6 from './image/di5.png';
import di7 from './image/di6.jpg';

const slidesData = [

  { src:di1},
  { src:di4},

  { src:di2},
  
  { src:di3},
  { src:di5},
  { src:di6},
  { src:di7}
];
const DigitalMarketing = () => {

  const trackRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const track = trackRef.current;
    const slideWidth = 500;
    const gapWidth = 10;
    const totalWidth = (slideWidth + gapWidth) * slidesData.length;
    const duplicatedTotalWidth = totalWidth * 2;
    const halfTotalWidth = duplicatedTotalWidth / 2;
    track.style.setProperty('--total-width', `${halfTotalWidth}px`);
    const baseDuration = 40;
    const baseWidth = 5000;
    const scrollDuration = (halfTotalWidth / baseWidth) * baseDuration;
    track.style.setProperty('--scroll-duration', `${scrollDuration}s`);
  }, []);

  const handleSlideClick = (path) => {
    navigate(path);
  };

  return (
    <section className="digicar-container">
      <h1 className=' carousel-title'>Sample Marketing</h1>
      <div className="digicar" aria-live="polite" aria-label="Image Carousel">
        <div className="digicar-track" ref={trackRef}>
          {slidesData.concat(slidesData).map((slide, index) => (
            <div className="digicar-slide" key={index} onClick={() => handleSlideClick(slide.path)}>
              <img className='digicar-slide-img' src={slide.src} alt={slide.heading} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default DigitalMarketing;






