import React from 'react';
import './seo.css';  
import seo from './image/seo1.jpg';
import seoi from './image/ui.png'
import socialmedia from './image/social-media.png'
import content from './image/content.png'
import digitalmedia from './image/digital media.png'
import googleanalytics from './image/google analytics.png'
import email from './image/email marketing.png'
import videomarketing from './image/video-marketing.png'
import googlead from './image/ad google.png'
import w1 from './image/availability.png';
import w2 from './image/quick page.png';
import w3 from './image/load very fast.png';
import w4 from './image/quick page.png';
import w5 from './image/reduce risk.png';
import w6 from './image/save on development.png';
import dm1 from './image/dm1.png';
import dm2 from './image/dm2.png';
import dm3 from './image/dm3.png';
import dm4 from './image/dm4.png';
import dm5 from './image/v8.png';
import dm6 from './image/ve1.png';
import dm7 from './image/ve5.png';
import dm8 from './image/ve9.png';


const Seo = () => {
  return (
    <div>
      <section className="seo">
        <div className="seo-container">
          <div className="seo-row">
            <div className="seo-col1" data-aos="fade-right">
              <div>
                <p className="seo-title">Digital Marketing Company</p>
                <h1 className="seo-head">Boost Your Business with Our Expertise</h1>
                <p className="seo-para">
                  Vetri It Systems excels in providing all types of digital marketing services. As a leading company in this field, we focus on enhancing the online presence of businesses and brands. We offer the best digital marketing services in Tirunelveli/Tenkasi, covering areas such as SEO, SMO, SMM, PPC, Google ads, and more. Our expert team is skilled in digital marketing strategies and knows how to improve your brand’s visibility and value.
                </p>
              </div>
            </div>
            <div className="seo-col2" data-aos="fade-left">
              <div className="seo-img">
                <img className="img-ani" src={seo} alt="Digital Marketing Services" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='web-list'>
              <div className='web-list-cont'>
              <h1 className='web-list-title'>What we Serve</h1>

                <div className='web-list-row'>
                    <div className='web-list-col1'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={seoi}></img>
                            <h1 className='web-list-head'>SEO</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>2000</span> </p>
                       <p className='web-list-para'>
                       SEO is a Process to Show your Website as First whenever the Customer is searching with your Business Keywords in Google..
</p>                        </div>
                        </div>
                        <div className='web-list-col2'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={socialmedia}></img>
                            <h1 className='web-list-head'>Social Media Management</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>5000</span> </p>
                       <p className='web-list-para'>
                       Social Media Management involves Managing all your Social Media Accounts like Facebook, Instagram, Twitter, Youtube and Reaching the Correct Customers of your Business to Improve the Business Visibility & Sales..
</p>                        </div>
                        </div>
                        <div className='web-list-col3'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={content}></img>
                            <h1 className='web-list-head'>Content Creation</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>1500</span> </p>
                       <p className='web-list-para'> Creating Visualise Content for your Business with Posters, Flyers & Videos with Animation to Reach Wide Range of Customers to Increase your Business..</p>
                        </div>
                        </div>
                        <div className='web-list-col4'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={digitalmedia}></img>
                            <h1 className='web-list-head'>Digital Media strategy and plan</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>1000</span> </p>
                       <p className='web-list-para'>A digital media strategy and plan define how to use digital channels to achieve business goals, engage audiences, and drive growth through targeted content and campaigns.</p>
                        </div>
                        </div>
                        <div className='web-list-col1'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={googleanalytics}></img>
                            <h1 className='web-list-head'>Google analytics</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>3000</span> </p>
                       <p className='web-list-para'>Google Analytics is a web analytics service that tracks and reports website traffic, user behavior, and conversion metrics to help businesses make data-driven decisions.</p>
                        </div>
                        </div>     
                           <div className='web-list-col2'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={email}></img>
                            <h1 className='web-list-head'>Email Marketing</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>5000</span> </p>
                       <p className='web-list-para'>Email marketing involves sending targeted messages to a list of subscribers to promote products, engage customers, and drive conversions.</p>
                        </div>
                        </div>                       
                         <div className='web-list-col3'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={videomarketing}></img>
                            <h1 className='web-list-head'>Video Marketing</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>7000</span> </p>
                       <p className='web-list-para'>Video marketing uses video content to promote products, build brand awareness, and engage audiences across various platforms.</p>
                        </div>
                        </div>                       
                         <div className='web-list-col4'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={googlead}></img>
                            <h1 className='web-list-head'>Google ad grant</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>5000</span> </p>
                       <p className='web-list-para'>The Google Ad Grant provides eligible nonprofits with free Google Ads credits to promote their missions and connect with potential supporters through search advertising. </p>
                        </div>
                        </div>
                    </div>
                    </div>          
            </section>
            <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>SEO</h1>
        <div className='web-detail-row'>

        <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w1}></img>
               </div>
               <div className='detail-why-p'>
                <p>Improves your website’s ranking in search results, making it easier for potential customers to find you.</p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img' >
                <img  src={w2}></img>
               </div>
               <div className='detail-why-p'>
                <p>Drives more organic traffic to your site by targeting relevant keywords.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w3}></img>
               </div>
               <div className='detail-why-p'>
                <p>Provides a high ROI compared to paid advertising by attracting free, targeted traffic.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w4}></img>
               </div>
               <div className='detail-why-p'>
                <p>Higher search rankings build trust and credibility with users.                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w5}></img>
               </div>
               <div className='detail-why-p'>
                <p> Helps you stay ahead of competitors who may not be optimizing their sites effectively.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w6}></img>
               </div>
               <div className='detail-why-p'> 
                <p> Enhances site usability and performance, leading to higher user satisfaction.                </p>
               </div>
                      </div>
                  </div>
                  </div>
                        </div>
          <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={dm6} alt="Static Website" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>Social Media Management</h1>
        <div className='web-detail-row'>
        <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={dm1} alt="Static Website" />
            </div>
          </div>
          <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w1}></img>
               </div>
               <div className='detail-why-p'>
                <p>Increases your brand’s visibility and reach through social media platforms.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img' >
                <img  src={w2}></img>
               </div>
               <div className='detail-why-p'>
                <p>Allows direct interaction with your audience, fostering stronger relationships.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w3}></img>
               </div>
               <div className='detail-why-p'>
                <p>Provides a platform for sharing content and driving traffic to your website.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w4}></img>
               </div>
               <div className='detail-why-p'>
                <p>Offers valuable data on audience behavior and content performance.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w5}></img>
               </div>
               <div className='detail-why-p'>
                <p>Helps manage your brand’s reputation by monitoring and responding to feedback.
                  </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>Enables targeted ad campaigns to reach specific demographics and interests.                </p>
               </div>
                      </div>
                      </div>
                  </div>
                        </div>

        </div>
      </div>
    </section>


    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>Content Creation</h1>
        <div className='web-detail-row'>

        <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w1}></img>
               </div>
               <div className='detail-why-p'>
                <p>Captures and holds the attention of your audience with valuable and relevant content.                </p>
               </div>
</div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img' >
                <img  src={w2}></img>
               </div>
               <div className='detail-why-p'>
                <p>Establishes your business as an expert in your field through informative and high-quality content.

                </p>
                </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w3}></img>
               </div>
               <div className='detail-why-p'>
                <p> Improves search engine rankings and drives organic traffic through optimized content.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w4}></img>
               </div>
               <div className='detail-why-p'>
                <p>Attracts and converts potential customers through compelling calls-to-action.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w5}></img>
               </div>
               <div className='detail-why-p'>
                <p>Encourages users to share your content, increasing your reach and visibility.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>Educates your audience about your products or services, leading to better-informed purchasing decisions.                </p>
               </div>
               </div>
                      </div>
                  </div>
                        </div>
                    <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={dm5} alt="Static Website" />
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>Digital Media statergy and plan</h1>
        <div className='web-detail-row'>
        <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={dm2} alt="Static Website" />
            </div>
          </div>
          <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w1}></img>
               </div>
               <div className='detail-why-p'>
                <p>Ensures that your digital efforts are aligned with your business objectives and goals.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img' >
                <img  src={w2}></img>
               </div>
               <div className='detail-why-p'>
                <p> Helps identify and reach your target audience effectively through appropriate channels.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w3}></img>
               </div>
               <div className='detail-why-p'>
                <p>Optimizes the use of resources and budget across different digital channels.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w4}></img>
               </div>
               <div className='detail-why-p'>
                <p> Enhances the effectiveness of your campaigns by using data-driven insights.

                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w5}></img>
               </div>
               <div className='detail-why-p'>
                <p>Maintains a consistent brand message and experience across all digital platforms.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={w6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>Allows you to measure and adjust strategies based on performance metrics and feedback.                </p>
               </div>
                      </div>
                      </div>
                  </div>
                        </div>
        </div>
      </div>
    </section>


    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>Google analytics</h1>
        <div className='web-detail-row'>
        <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w1}></img>
               </div>
               <div className='detail-why-p'>
                <p>Provides insights into user behavior to inform marketing and business strategies.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img' >
                <img  src={w2}></img>
               </div>
               <div className='detail-why-p'>
                <p> Helps understand where your traffic is coming from and how users interact with your site.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w3}></img>
               </div>
               <div className='detail-why-p'>
                <p>Measures the effectiveness of your campaigns in driving conversions and achieving goals.                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w4}></img>
               </div>
               <div className='detail-why-p'>
                <p> Offers detailed information about your audience’s demographics and interests.
                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w5}></img>
               </div>
               <div className='detail-why-p'>
                <p>Tracks key performance indicators (KPIs) to gauge the success of your digital efforts.                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={w6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>Identifies areas for improvement to enhance user experience and site performance.
                </p>
               </div></div>
                      </div>
                  </div>
                        </div>
                    <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={dm8} alt="Static Website" />
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>Email Marketing</h1>
        <div className='web-detail-row'>
        <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={dm4} alt="Static Website" />
            </div>
          </div>
          <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w1}></img>
               </div>
               <div className='detail-why-p'>
                <p> Enables personalized and direct communication with your audience.</p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w2}></img>
               </div>
               <div className='detail-why-p'>
                <p>Helps retain customers through regular updates and engagement.                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w3}></img>
               </div>
               <div className='detail-why-p'>
                <p>Provides a cost-effective way to reach a large audience with targeted messages.</p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w4}></img>
               </div>
               <div className='detail-why-p'>
                <p>Assists in nurturing leads and guiding them through the sales funnel.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>

             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w5}></img>
               </div>
               <div className='detail-why-p'>
                <p>Tracks email performance through metrics like open rates and click-through rates.
                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>Allows for tailored messaging to different segments of your email list for better engagement.
                .
                </p>
               </div>
               </div>
                      </div>
                  </div>
                        </div>
        </div>
      </div>
    </section>


    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>Video Marketing</h1>
        <div className='web-detail-row'>

        <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w1}></img>
               </div>
               <div className='detail-why-p'>
                <p> Captures attention and increases engagement with dynamic and visually appealing content.                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img' >
                <img  src={w2}></img>
               </div>
               <div className='detail-why-p'>
                <p> Effectively conveys your brand story and message through compelling visuals and narratives.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w3}></img>
               </div>
               <div className='detail-why-p'>
                <p>Drives higher conversion rates by showcasing products or services in action.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w4}></img>
               </div>
               <div className='detail-why-p'>
                <p>Boosts search engine rankings and traffic by optimizing video content.
                </p>
                </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w5}></img>
               </div>
               <div className='detail-why-p'>
                <p>Encourages sharing on social media, expanding your reach and visibility.</p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={w6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>Builds a stronger connection with your audience through relatable and engaging video content.
                </p>
                </div>
               </div>
                      </div>
                  </div>
                        </div>
                    <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={dm7} alt="Static Website" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>Google ad grant</h1>
        <div className='web-detail-row'>
        <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={dm6} alt="Static Website" />
            </div>
          </div>
          <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w1}></img>
               </div>
               <div className='detail-why-p'>
                <p> Provides free advertising credits for nonprofits, reducing marketing costs.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img' >
                <img  src={w2}></img>
               </div>
               <div className='detail-why-p'>
                <p> Enhances your nonprofit’s online presence through search engine ads.                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={w3}></img>
               </div>
               <div className='detail-why-p'>
                <p>Allows you to target specific keywords and reach relevant audiences effectively.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w4}></img>
               </div>
               <div className='detail-why-p'>
                <p> Helps attract potential donors and supporters to your cause.
                </p>
               </div>
             </div>
             
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={w5}></img>
               </div>
               <div className='detail-why-p'>
                <p> Offers tools to manage and optimize ad campaigns for better performance.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>Provides insights into ad performance and user interactions, helping you refine your strategy.</p>
               </div>
                      </div>
                      </div>
                  </div>
                        </div>
        </div>
      </div>
    </section>


    
    </div>
  );
};

export default Seo;
