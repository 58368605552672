import React from 'react';
import './seo.css';  
import seo from './image/softtest.png';

const Seo = () => {
  return (
    <div>
      <section className="seo">
        <div className="seo-container">
          <div className="seo-row">
            <div className="seo-col1" data-aos="fade-right">
              <div>
                <p className="seo-title">Top Software Testing Services</p>
                <h1 className="seo-head">Ensuring Quality and Reliability</h1>
                <p className="seo-para">
                  Vetri Technology Solutions excels in providing comprehensive software testing services. Our expert team is dedicated to ensuring the highest quality and reliability for your software. We offer a wide range of testing services including manual testing, automated testing, performance testing, and security testing. Our goal is to help businesses deliver flawless software to their users.
                </p>
              </div>
            </div>
            <div className="seo-col2" data-aos="fade-left">
              <div className="seo-img">
                <img className="img-ani" src={seo} alt="Software Testing Services" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="seo-main">
        <div className="seo-main-container">
          <h1>Services We Offer</h1>
          <h1>Top Software Testing Company</h1>
          <p>
            Vetri Technology Solutions is renowned for its cutting-edge software testing strategies, ensuring your applications perform seamlessly.
          </p>
          <div className="seo-main-row"></div>
        </div>
      </section> */}

      <section className="seo-want" data-aos="fade-up">
        <div className="seo-want-container">
          <h1 className="seo-want-title">Software Testing Services</h1>
          <h1 className="seo-want-head">Want to ensure your software is bug-free and reliable?</h1>
          <p className="seo-want-para">
            Our team of software testing experts keeps you updated on every stage of the testing process, providing you with full transparency and control. Feel free to ask any questions at any time.
          </p>
        </div>
      </section>

      <section className='faq' data-aos="fade-up">
        <div className="faq-container">
          <h1 className="faq-title">Software Testing Agency</h1>
          <h1 className="faq-head">Frequently Asked Questions ( FAQ )</h1>
          <details>
            <summary>What is manual testing and why is it important?</summary>
            <div className="faq-content">
              Manual testing involves manually checking software for defects. It is crucial for identifying user interface issues and ensuring a seamless user experience.
            </div>
          </details>
          <details>
            <summary>How does automated testing benefit my project?</summary>
            <div className="faq-content">
              Automated testing uses scripts to run tests, improving efficiency and coverage. It is essential for large projects with frequent updates.
            </div>
          </details>
          <details>
            <summary>What is performance testing?</summary>
            <div className="faq-content">
              Performance testing evaluates the speed, responsiveness, and stability of software under various conditions. It ensures the software performs well under load.
            </div>
          </details>
          <details>
            <summary>How often should I perform security testing?</summary>
            <div className="faq-content">
              Regular security testing is vital. It should be conducted at least quarterly to protect against emerging threats and vulnerabilities.
            </div>
          </details>
        </div>
      </section>
    </div>
  );
};

export default Seo;
