import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from './logo.png';
import "./Navbar.css";
import QuoteForm from "./contactform";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setIsDropdownOpen(false); 
      }
  };

  const closeMenu = () => {
    setIsOpen(false);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const openQuoteForm = () => {
    setIsQuoteFormOpen(true);
  };

  const closeQuoteForm = () => {
    setIsQuoteFormOpen(false);
  };

  return (
    <div>
      <header className="navbar">
        <nav className="navbar-bottom">
          <div className="logo">
            <Link to="/" onClick={closeMenu}>
              <img src={logo} alt="VIS" />
            </Link>
          </div>
          <button className="menu-toggle" onClick={toggleMenu}>
            {isOpen ? "✖" : "☰"}
          </button>
          <ul className={`nav-links ${isOpen ? "open" : ""}`}>
            <li id="menu">
              <Link to="/" onClick={closeMenu}>VIS PROCESS</Link>
            </li>
            <li className="dropdown" id="menu">
              <button onClick={toggleDropdown} className="dropdown-toggle">
                DIGITAL SERVICES {isDropdownOpen ? <box-icon name='chevron-up'></box-icon> : <box-icon type='solid' name='chevron-down'></box-icon>}
              </button>
              {isDropdownOpen && (
                <ul className="dropdown-menu" style={{ listStyleType: "none" }}>
                  <li className="drop-li">
                    <Link to="/Webdesign" onClick={closeMenu}>
                      <div className="d-logo">
                        <div>
                          <box-icon name='world'></box-icon>
                        </div>
                        <div>
                          Web Development
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="drop-li">
                    <Link to="/Soft" onClick={closeMenu}>
                      <div className="d-logo">
                        <div>
                          <box-icon name='code-alt'></box-icon>
                        </div>
                        <div> Software Development</div>
                      </div>
                    </Link>
                  </li>
                  <li className="drop-li">
                    <Link to="/DigitalMarket" onClick={closeMenu}>
                      <div className="d-logo">
                        <div>
                          <box-icon name='chart'></box-icon>
                        </div>
                        <div> Digital Marketing</div>
                      </div>
                    </Link>
                  </li>
                  <li className="drop-li">
                    <Link to="/design" onClick={closeMenu}>
                      <div className="d-logo">
                        <div>
                          <box-icon name='briefcase-alt'></box-icon>
                        </div>
                        <div>UI/UX Design</div> 
                      </div>
                    </Link>
                  </li>
                  <li className="drop-li">
                    <Link to="/SoftTest" onClick={closeMenu}>
                      <div className="d-logo">
                        <div>
                          <box-icon name='bug'></box-icon>
                        </div>
                        <div> Software Testing</div>
                      </div>
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li id="menu">
              <Link to="/portfolio" onClick={closeMenu}>TREASURE</Link>
            </li>
            <li>
              <button onClick={openQuoteForm} className="quote-button">
                GET A QUOTE
              </button>
            </li>
          </ul>
        </nav>
        <QuoteForm isOpen={isQuoteFormOpen} onClose={closeQuoteForm} />
      </header>
    </div>
  );
};

export default Navbar;
