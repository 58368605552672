import React from 'react';
import hydro from './image/hydro.png'
import  builders from './image/builders.png'

const Websites = () => {
    const portfolioProjects = [
        { id: 1, name: "HydroTork", link: "https://hydrotork.in/", image: hydro },
        { id: 2, name: "Nagercoil Builders", link: "https://nagercoilbuilders.com/", image: builders }
      ];
  return (
    <div>
        <section>
        <div className="port-web-pro-container">
          <div className="port-web-pro-row">
            {portfolioProjects.map((project) => (
              <div key={project.id} className="port-web-pro-col">
                <div className="port-web-pro-card">
                  <img src={project.image} alt={project.name} className="project-img" />
                  <h1 className='pro-h1'>{project.name}</h1>
                  <div className='pro-a'>
                  <a href={project.link} >view site</a>
                </div>
              </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Websites;
