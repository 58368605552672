import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Posters from './pages/posters';
import Seo from './pages/Seo';
import {Soft} from './pages/Soft';
import {Webdesign} from './pages/Webdesign';
import SoftTest from './pages/SoftTest';
import {Design} from './pages/uiuxdesign';
import DigitalMarket from './pages/DigitalMarket';


const App = () => {
  return (
    <Router>
      <div>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />}/>  
          <Route path="/seo" element={<Seo />} />
          <Route path="/webdesign" element={<Webdesign />} />
          <Route path="/soft" element={<Soft />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/posters" element={<Posters />} />
          <Route path="/softtest" element={<SoftTest />} />
          <Route path="/design" element={<Design />} />
          <Route path="/digitalmarket" element={<DigitalMarket />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
// import React from 'react';
// import { BrowserRouter as Router, Routes } from 'react-router-dom';
// import Navbar from './components/Navbar';
// import Footer from './components/Footer';
// import Home from './pages/About';
// import Services from './pages/Services';
// import Portfolio from './pages/Portfolio';
// import Posters from './pages/Posters';
// import Contact from './pages/Contact';
// import Seo from './pages/Seo';
// import { Soft } from './pages/Soft';
// import { Webdesign } from './pages/Webdesign';
// import BankAppDeve from './pages/BankAppDeve';
// import SoftTest from './pages/SoftTest';
// import ItConsulting from './pages/ItConsulting';
// import DigitalMarket from './pages/DigitalMarket';
// import DelayedRoute from './components/Delayroute';

// const App = () => {
//   return (
//     <Router>
//       <div>
//         <Navbar />
//         <Routes>
//           <DelayedRoute path="/" element={<Home />} />
//           <DelayedRoute path="/services" element={<Services />} />
//           <DelayedRoute path="/seo" element={<Seo />} />
//           <DelayedRoute path="/webdesign" element={<Webdesign />} />
//           <DelayedRoute path="/soft" element={<Soft />} />
//           <DelayedRoute path="/portfolio" element={<Portfolio />} />
//           {/* <DelayedRoute path="/posters" element={<Posters />} /> */}
//           <DelayedRoute path="/contact" element={<Contact />} />
//           <DelayedRoute path="/bankAppdeve" element={<BankAppDeve />} />
//           <DelayedRoute path="/softtest" element={<SoftTest />} />
//           <DelayedRoute path="/itconsulting" element={<ItConsulting />} />
//           <DelayedRoute path="/digitalmarket" element={<DigitalMarket />} />
//         </Routes>
//         <Footer />
//       </div>
//     </Router>
//   );
// };

// export default App;