import React from 'react'
import './webdesign.css'
import web from './image/web.jpg';
import ve1 from './image/ve4.png';
import ve2 from './image/ve2.png';
import ve3 from './image/ve3.png';
import ve4 from './image/ve8.png';
import im5 from './image/im5.jpg';
import web1 from './image/web1.gif';
import web2 from './image/web2.gif';
import web3 from './image/web3.gif';
import web4 from './image/web4.gif';
import web5 from './image/web5.gif';
import web6 from './image/web6.gif';
import w1 from './image/wd1.png';
import w2 from './image/wd2.png';
import w3 from './image/load very fast.png';
import w4 from './image/wd3.png';
import w5 from './image/wd4.png';
import w6 from './image/wd5.png';
import st1 from './image/st1.jpg';
import st2 from './image/st2.jpg';
import st3 from './image/st3.jpg';
import st4 from './image/st4.jpg';
import st5 from './image/st5.jpg';
import st6 from './image/st6.jpg';
import dy1 from './image/dy1.jpg';
import dy2 from './image/dy2.jpg';
import dy3 from './image/dy3.jpg';
import dy4 from './image/dy4.jpg';
import dy5 from './image/dy5.jpg';
import dy6 from './image/dy6.jpg';
import ec1 from './image/ec1.png';
import ec2 from './image/ec.png';
import ec3 from './image/ec3.png';
import ec4 from './image/ec4.png';
import ec5 from './image/ec5.png';
import ec6 from './image/ec6.png';
import ex1 from './image/html.jpg';
import ex2 from './image/react.jpg';
import ex3 from './image/mongodb.jpg';
import ex4 from './image/mysql.jpg';
import ex5 from './image/node.jpg';
import ex6 from './image/php.jpg';
import ex7 from './image/angular.jpg';
import ex8 from './image/spotify.jpg';
import ex9 from './image/wix.jpg';
import ex10 from './image/wordpress.jpg';
import Static from './image/static.png';
import dynamic from './image/webdy.png';
import Ecom from './image/ecom.png';
import Redesign from './image/redesign.png';

export const Webdesign = () => {
    return (
        <div>
            <section className="web" data-aos="fade-up">
                <div className="web-container">
                    <div className="web-row">
                        <div className="web-col1">
                            <div>
                                <p className="web-title">BEST WEBSITE DEVELOPMENT COMPANY</p>
                                <h1 className="web-head">Enhancing Your Business with Top-Notch Web Development</h1>
                                <p className="web-para">
                                Give Better Visibility to your Business with the Dynamic & Responsive Websites to reach Millions of People and Boost your Business!!! Give a Try to launch your website within 2 Days.. !!! Vetri IT Systems Provides a Website within 2 Days..!!! Connect with Us to Boost Your Business Today!!!
                                </p><br />
                            </div>
                        </div>
                        <div className="web-col2">
                            <div className="web-img">
                                <img src={web} alt="Web Design" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='web-list'>
              <div className='web-list-cont'>
              <h1 className='web-list-title'>What we Serve</h1>

                <div className='web-list-row'>
                    <div className='web-list-col1'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={Static}></img>
                            <h1 className='web-list-head'>Static Website</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>3200</span> </p>
                       <p className='web-list-para'> A Basic Website which will give a quick Visibility to your business through Digital Medium..</p>
                        </div>
                        </div>
                        <div className='web-list-col2'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={dynamic}></img>
                            <h1 className='web-list-head'>Dynamic Website</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>7000</span> </p>
                       <p className='web-list-para'> Quick & Interactive Website which will give a Great Visibility to your business through Digital Medium and pass an Interactive UI to your Customers..</p>
                        </div>
                        </div>
                        <div className='web-list-col3'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={Ecom}></img>
                            <h1 className='web-list-head'>E-Commerce Website</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>15000</span> </p>
                       <p className='web-list-para'> A Perfect Package to Boost your Sales and Increase the Customers with an User Friendly Website with UPI Payment & Cards EMI Facilities to your customers..</p>
                        </div>
                        </div>
                        <div className='web-list-col4'>
                        <div className='web-list-card'>
                            <img className='web-list-img' src={Redesign}></img>
                            <h1 className='web-list-head'>Website Redesign</h1>
                            <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>5000</span> </p>
                       <p className='web-list-para'>We perfectly redesign your Website as per your expectations in the lower Price..</p>
                        </div>
                        </div>
                    </div>
                    </div>     
            </section>


            <section className='web-detail'>
                <div className='web-detail-cont'>
                    <h1 className='web-detail-title'>Static Website</h1>
                    <div className=' web-detail-row'>
                        <div className='web-detail-col1'>
                             <div className='web-detail-img'>
                                <img src={ve1}></img>
                             </div>
                        </div>
                        <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
              <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={st1}></img>
               </div>
               <div className='detail-why-p'>
                <p>"Static websites load very fast, so your visitors won't have to wait."</p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img' >
                <img  src={st4}></img>
               </div>
               <div className='detail-why-p'>
                <p>Enjoy quick page loads for a better user experience.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={st2}></img>
               </div>
               <div className='detail-why-p'>
                <p>Save on development and hosting expenses.</p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={st3}></img>
               </div>
               <div className='detail-why-p'>
                <p>Reduce the risk of common web attacks.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={st5}></img>
               </div>
               <div className='detail-why-p'>
                <p>Ensure high availability with minimal downtime</p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={st6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>Easily update and maintain your site.
                </p>
               </div>
               </div>
                      </div>
                  </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>Dynamic Website</h1>
        <div className='web-detail-row'>
        <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={dy1}></img>
               </div>
               <div className='detail-why-p'>
                <p>    Dynamic websites offer personalized content based on user interactions and preferences.</p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img' >
                <img  src={dy2}></img>
               </div>
               <div className='detail-why-p'>
                <p>They allow users to interact with the site, such as submitting forms and commenting.               </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={dy3}></img>
               </div>
               <div className='detail-why-p'>
                <p>They can display real-time data updates from databases and APIs.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={dy4}></img>
               </div>
               <div className='detail-why-p'>
                <p>Dynamic websites support complex functionalities like user authentication and content management.  </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>

                <img src={dy5}></img>
               </div>
               <div className='detail-why-p'>
                <p>They are ideal for e-commerce, blogs, and social networks where content frequently changes.                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={dy6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>They can integrate with various third-party services and APIs for enhanced functionality.
                .
                </p>
               </div>
               </div>
                      </div>
                  </div>
                        </div>
          <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={ve2} alt="Static Website" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>E-Commerce Website</h1>
        <div className='web-detail-row'>
          <div className='web-detail-col1'>
            
            <div className='web-detail-img'>
              <img src={ve3} alt="Static Website" />
            </div>
          </div>
          <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={ec1}></img>
               </div>
               <div className='detail-why-p'>
                <p>E-commerce websites allow customers to shop online anytime, increasing convenience.</p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img' >
                <img  src={ec2}></img>
               </div>
               <div className='detail-why-p'>
                <p>They reach a global audience, expanding market potential.
                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={ec3}></img>
               </div>
               <div className='detail-why-p'>
                <p>These websites offer personalized shopping experiences through recommendations.
                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={ec4}></img>
               </div>
               <div className='detail-why-p'>
                <p>They enable easy management of inventory and sales tracking.
                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={ec5}></img>
               </div>
               <div className='detail-why-p'>
                <p>E-commerce websites provide various payment options for customer convenience.
                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={ec6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>They facilitate direct communication with customers through chat and support features.
                </p>
               </div>
                 </div>
                      </div>
                  </div>
                        </div>
        </div>
      </div>
    </section>
    <section className='web-detail'>
      <div className='web-detail-cont'>
        <h1 className='web-detail-title'>Website Redesigning</h1>
        <div className='web-detail-row'>
        <div className='web-detail-col'>
             <div className='detail-why-row'>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w1}></img>
               </div>
               <div className='detail-why-p'>
                <p>Website redesigning improves user experience with modern, intuitive design.</p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img' >
                <img  src={w2}></img>
               </div>
               <div className='detail-why-p'>
                <p>It enhances website performance with faster load times and better responsiveness.
                </p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img  src={w3}></img>
               </div>
               <div className='detail-why-p'>
                <p>Redesigning can boost SEO rankings through updated content and structure.</p>
               </div>
             </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img  src={w4}></img>
               </div>
               <div className='detail-why-p'>
                <p>It helps reflect current branding and company goals.

                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>
               <div className='detail-why-img'>
                <img src={w5}></img>
               </div>
               <div className='detail-why-p'>
                <p>A redesigned website can increase user engagement and conversions.
                </p>
               </div>
               </div>
             </div>
             <div className='detail-why-col'>
             <div className='detail-why-card'>

               <div className='detail-why-img'>
                <img src={w6}></img>
               </div>
               <div className='detail-why-p'> 
                <p>It ensures compatibility with the latest web technologies and devices.
                </p>
               </div>
               </div>
                      </div>  
              </div>          
              </div>
              <div className='web-detail-col1'>
            <div className='web-detail-img'>
              <img src={ve4} alt="Static Website" />
            </div>
          </div>
          </div>
        </div>    
       </section>

            <section className="web-ser" data-aos="fade-up">
                <div className="web-ser-container">
                    <div className="web-ser-row">
                        <div className="web-ser-col1">
                            <div className="web-ser-img">
                                <img src={im5} alt="Web Services" />
                            </div>
                        </div>
                        <div className="web-ser-col2">
                            <div>
                                <h1 className="web-ser-head">Our Strategies To Make You Accomplish Your Goals</h1>
                                <p className="web-ser-para">
                                    A good website starts with a good strategy. This is how we deliver excellence on every
                                    website we craft.
                                </p>
                            </div>
                            <div className="web-ser-box">
                                <div className="web-ser-box-col">
                                    <img src={web1} alt="Requirement" width="100px" />
                                    <p>Requirement</p>
                                </div>
                                <div className="web-ser-box-col">
                                    <img src={web2} alt="Strategy" width="100px" />
                                    <p>Strategy</p>
                                </div>
                                <div className="web-ser-box-col">
                                    <img src={web3} alt="Design" width="100px" />
                                    <p>Design</p>
                                </div>
                                <div className="web-ser-box-col">
                                    <img src={web4} alt="Development" width="100px" />
                                    <p>Development</p>
                                </div>
                                <div className="web-ser-box-col">
                                    <img src={web5} alt="Testing" width="100px" />
                                    <p>Testing</p>
                                </div>
                                <div className="web-ser-box-col">
                                    <img src={web6} alt="Launch & support" width="100px" />
                                    <p>Launch & support</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='we-exp'>
        <div className='we-exp-cont'>
          <h1>We Are Expertise In:</h1>
          <div className='we-exp-row'>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>
               <img src={ex1}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>

               <img src={ex2}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>

               <img src={ex3}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
                <div className='we-exp-img'>
               <img src={ex4}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>

               <img src={ex5}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>
               <img src={ex6}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>
               <img src={ex7}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>
               <img src={ex8}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>
               <img src={ex9}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>
               <img src={ex10}></img>
               </div>
               </div>
            </div>

          </div>
        </div>

</section>
        </div>
    )
};
