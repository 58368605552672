import React from 'react'
import './process.css'
import arr from './image/a1.jpg';
import arr1 from './image/a2.jpg';
import arr2 from './image/m3.jpg';
import arr3 from './image/m4.jpg';
import arr4 from './image/m1.jpg';
import arr5 from './image/m2.jpg';

const Process = () => {
    return (
        <div>
            <section className="map" data-aos="fade-up">
                <div className="map-container">
                    <h1 className='map-title'>VIS Process...</h1>
                    <div className="map-row">
                        <div className="map-col" data-aos="zoom-in">
                            <div className="map-box">
                                <div className="map-img">
                                    <img src={arr5} alt="Start A Project" />
                                </div>
                                <div className="map-detail">
                                    <h3 className="map-head">Requirement</h3>
                                </div>
                            </div>
                        </div>
                        <div className="map-col1" data-aos="zoom-in">
                            <div className="map-box">
                                <div className="map-img">
                                    <img src={arr4} alt="Project Analysis" />
                                </div>
                                <div className="map-detail">
                                    <h3 className="map-head">Strategy</h3>
                                </div>
                            </div>
                        </div>
                        <div className="map-col1" data-aos="zoom-in">
                            <div className="map-box">
                                <div className="map-img">
                                    <img src={arr2} alt="Start A Project" />
                                </div>
                                <div className="map-detail">
                                    <h3 className="map-head">Design</h3>
                                </div>
                            </div>
                        </div>
                        <div className="map-col1" data-aos="zoom-in">
                            <div className="map-box">
                                <div className="map-img">
                                    <img src={arr3} alt="Deliver to Success" />
                                </div>
                                <div className="map-detail">
                                    <h3 className="map-head">Development</h3>
                                </div>
                            </div>
                        </div>
                        <div className="map-col1" data-aos="zoom-in">
                            <div className="map-box">
                                <div className="map-img">
                                    <img src={arr1} alt="Deliver to Success" />
                                </div>
                                <div className="map-detail">
                                    <h3 className="map-head">Testing</h3>
                                </div>
                            </div>
                        </div>
                        <div className="map-col1" data-aos="zoom-in">
                            <div className="map-box">
                                <div className="map-img">
                                    <img src={arr} alt="Deliver to Success" />
                                </div>
                                <div className="map-detail">
                                    <h3 className="map-head">Launch & support</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Process;

