import React from 'react'
import './seo.css'
import Im3 from './image/im3.png';
import Native from './image/native mobile.png';
import Hybrid from './image/softim1.png';
import Progressive from './image/Progressive Web App.png';
import Embed from './image/Embedded Chip.png';
import w1 from './image/p1.png';
import w2 from './image/p2.png';
import w3 from './image/p3.png';
import w4 from './image/p4.png';
import s1 from './image/s1.png';
import s2 from './image/s2.png';
import s3 from './image/s3.png';
import s4 from './image/s4.png';
import s5 from './image/s5.png';
import s6 from './image/s6.png';
import s7 from './image/s7.png';
import s8 from './image/s8.png';
import s9 from './image/s9.png';
import s10 from './image/s10.png';
import s11 from './image/s11.png';
import s12 from './image/s12.png';
import s13 from './image/s13.png';
import s14 from './image/s14.png';
import s15 from './image/s15.png';
import s16 from './image/s16.png';
import s17 from './image/s17.png';
import s18 from './image/s18.png';
import s19 from './image/s19.png';
import s20 from './image/s20.png';
import s21 from './image/s21.png';
import s22 from './image/s22.png';
import s23 from './image/s23.png';
import s24 from './image/s24.png';
 import exsoft1 from './image/ios.jpg'
import exsoft2 from './image/android.jpg'
import exsoft3 from './image/flutter.jpg'
import exsoft4 from './image/ionic.jpg'
import exsoft5 from './image/react-native.jpg'




export const Soft = () => {
  return (
    <div>
      <section className="seo">
        <div className="seo-container">
          <div className="seo-row">
            <div className="seo-col1" data-aos="fade-right">
              <div>
                <p className="seo-title">Customize Software Development</p>
                <h1 className="seo-head">Software Development</h1>
                <p className="seo-para">
                We are expertise to Build the software as per your Business Meets and we are capable enough to accommodate your Business Needs within 7 Days..
                </p>
              </div>
            </div>
            <div className="seo-col2" data-aos="fade-up-left">
              <div className="seo-img">
                <img className="img-ani" src={Im3} alt="SEO" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='web-list'>
        <div className='web-list-cont'>
          <h1 className='web-list-title'>What we Serve</h1>

          <div className='web-list-row'>
            <div className='web-list-col1'>
              <div className='web-list-card'>
                <img className='web-list-img' src={Native}></img>
                <h1 className='web-list-head'>Native Mobile</h1>
                <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>5000</span> </p>
                <p className='web-list-para'>It’s a Basic Mobile App which will give the Basic Information about your Business..</p>
              </div>
            </div>
            <div className='web-list-col2'>
              <div className='web-list-card'>
                <img className='web-list-img' src={Hybrid}></img>
                <h1 className='web-list-head'>Hybrid App mobile Development</h1>
                <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>7000</span> </p>
                <p className='web-list-para'> It’s a Responsive Mobile App which will have the UPI & Cards Payment Support to your Customers..</p>
              </div>
            </div>
            <div className='web-list-col3'>
              <div className='web-list-card'>
                <img className='web-list-img' src={Progressive}></img>
                <h1 className='web-list-head'>Progressive Web App Development</h1>
                <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>12000</span> </p>
                <p className='web-list-para'>Progressive Web Apps (PWAs) combine web and native app features to offer a reliable, responsive, and installable experience across multiple platforms with a single codebase.</p>
              </div>
            </div>
            <div className='web-list-col4'>
              <div className='web-list-card'>
                <img className='web-list-img' src={Embed}></img>
                <h1 className='web-list-head'>Wearable and Embeded Software</h1>
                <p className='web-price'><span>STARTS FROM <i className='bx bx-rupee bx-tada'></i>25000</span> </p>
                <p className='web-list-para'>Wearable software powers devices worn on the body, while embedded software operates within hardware systems to perform specific functions.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='web-detail'>
        <div className='web-detail-cont'>
          <h1 className='web-detail-title'>Native Mobile</h1>
          <div className='web-detail-row'>
            <div className='web-detail-col1'>
              <div className='web-detail-img'>
                <img src={w1} alt="Static Website" />
              </div>
            </div>
            <div className='web-detail-col'>
              <div className='detail-why-row'>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img'>
                      <img src={s1}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Native apps run faster and more smoothly because they are specifically designed for iOS or Android.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img' >
                      <img src={s2}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>They can use all the features of your phone, like the camera, GPS, and notifications, to offer more functionality.                </p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s3}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Native apps look and feel like they belong on your phone, providing a seamless experience.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s4}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>They offer stronger security, keeping your data safe. </p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img'>
                      <img src={s5}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Native apps are easy to find and trust since they are available on official app stores.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s6}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Native apps can still function without an internet connection, making them reliable anywhere.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='web-detail'>
        <div className='web-detail-cont'>
          <h1 className='web-detail-title'>Hybrid App mobile Development</h1>
          <div className='web-detail-row'>
            <div className='web-detail-col'>
              <div className='detail-why-row'>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s7}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p> Hybrid apps are generally cheaper to develop because they use a single codebase for both iOS and Android.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img' >
                      <img src={s8}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Developing one app for multiple platforms saves time, allowing for a quicker launch.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img'>
                      <img src={s9}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Maintaining a single codebase is easier and more cost-effective than maintaining separate codebases for each platform.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s10}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Hybrid apps can still access most device features through plugins and frameworks.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s11}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>With one app, you can reach users on both iOS and Android platforms, expanding your market.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s12}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Hybrid apps provide a consistent user experience across different platforms, ensuring your brand looks the same everywhere.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='web-detail-col1'>
              <div className='web-detail-img'>
                <img src={w2} alt="Static Website" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='web-detail'>
        <div className='web-detail-cont'>
          <h1 className='web-detail-title'>Progressive Web App Development</h1>
          <div className='web-detail-row'>
            <div className='web-detail-col1'>
              <div className='web-detail-img'>
                <img src={w3} alt="Static Website" />
              </div>
            </div>
            <div className='web-detail-col'>
              <div className='detail-why-row'>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img'>
                      <img src={s13}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>PWAs work on any device with a web browser, so you only need to develop one app for all platforms.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img' >
                      <img src={s13}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p> PWAs can work offline or with a poor internet connection by caching important resources.                </p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img'>
                      <img src={s15}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Developing a PWA is usually cheaper than building separate native apps for iOS and Android.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img'>
                      <img src={s16}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Users can access your app directly from their browser without needing to download it from an app store.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img'>
                      <img src={s17}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>PWAs update automatically, ensuring users always have the latest version without manual updates</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img'>
                      <img src={s18}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>PWAs are designed to load quickly and provide a smooth user experience, even on slower networks.       </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='web-detail'>
        <div className='web-detail-cont'>
          <h1 className='web-detail-title'>Wearable and Embeded Software</h1>
          <div className='web-detail-row'>

            <div className='web-detail-col'>
              <div className='detail-why-row'>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>
                    <div className='detail-why-img'>
                      <img src={s19}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Wearable and embedded software is designed for specific tasks and devices, ensuring optimal performance for those uses.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img' >
                      <img src={s20}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>These applications can provide real-time data and feedback, crucial for health monitoring, fitness tracking, and other time-sensitive activities.                </p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s21}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Embedded software integrates smoothly with hardware, offering a cohesive and efficient user experience.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s22}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p> Designed to be efficient, this software helps extend the battery life of wearable devices.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s23}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p>Embedded software often includes robust security measures to protect sensitive data.</p>
                    </div>
                  </div>
                </div>
                <div className='detail-why-col'>
                  <div className='detail-why-card'>

                    <div className='detail-why-img'>
                      <img src={s24}></img>
                    </div>
                    <div className='detail-why-p'>
                      <p> The software is lightweight and efficient, making the most of limited hardware resources without compromising performance.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='web-detail-col1'>
              <div className='web-detail-img'>
                <img src={w4} alt="Static Website" />
              </div>
            </div>
          </div>
        </div>
      </section>
<section className='we-exp'>
        <div className='we-exp-cont'>
          <h1>We Are Expertise In:</h1>
          <div className='we-exp-row'>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>
               <img src={exsoft1}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>

               <img src={exsoft2}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>

               <img src={exsoft3}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
                <div className='we-exp-img'>
               <img src={exsoft4}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>

               <img src={exsoft5}></img>
               </div>
               </div>
            </div>
          </div>
        </div>

</section>

    </div>
  )
}
