import React from 'react';
import kabilan from './image/pic1.jpg';
import mahesh from './image/pic4.jpg';
import pravin from './image/pic2.jpg';
import praveena from './image/pic3.jpg';
import Jebaraj from './image/pic5.jpg';
import './team.css';

const Team = () => {
    return (
        <div>
            <section className='team'>
                <div className='team-cont'>
                    <h1 className='team-title'>Meet Our Troops...</h1>
                    <div className='team-row'>
                        <div className='team-col'>
                            <div className='team-card'>
                                <div className='team-img'>
                                    <img src={pravin} alt='Pravin Kumar P'></img>
                                </div>
                                <h1 className='team-name'>Pravin Kumar P</h1>
                                <p className='team-role'>MD & CEO<br/>VETRI Companies</p>
                            </div>
                        </div>
                        {/* <div className='team-col'>
                            <div className='team-card'>
                                <div className='team-img'>
                                    <img src={Jebaraj} alt='Jebaraj S'></img>
                                </div>
                                <h1 className='team-name'>Jebaraj S</h1>
                                <p className='team-role'>VIS Business Manager</p>
                            </div>
                        </div> */}
                        <div className='team-col'>
                            <div className='team-card'>
                                <div className='team-img'>
                                    <img src={praveena} alt='Praveena S'></img>
                                </div>
                                <h1 className='team-name'>Praveena S</h1>
                                <p className='team-role'>VIS Project Manager</p>
                            </div>
                        </div>
                        <div className='team-col'>
                            <div className='team-card'>
                                <div className='team-img'>
                                    <img src={mahesh} alt='Maheshwaran S'></img>
                                </div>
                                <h1 className='team-name'>Maheshwaran S</h1>
                                <p className='team-role'>VIS Project Manager</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Team;
