import React from 'react';
import './Navbar.css';
import 'boxicons/css/boxicons.min.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='footer'>
  <div className='footer-cont'>
      <div className="footer-icons">
        <a href="https://wa.me/918438558527" className="footer-icon whatsapp-icon">
          <i className='bx bxl-whatsapp'></i>
        </a>
        <a href="tel:+918438558527" className="footer-icon phone-icon">
          <i className='bx bxs-phone'></i>
        </a>
      </div>
      <div className="footer-content">
        <div className="footer-section">
          <h2 className='foot-h'>Vetri IT Systems</h2>
         <div className='f-m'>
          <a href="tel:+918438558527">  <i className='bx bxs-phone'></i> +91-8438558527</a>
          </div> 
          <div className='f-m'>
          <a href="mailto:business@vetriit.com">  <i className='bx bxs-envelope'></i>business@vetriit.com</a>
          </div>
        </div>
        <div className="footer-section">
          <h2 className='foot-h'>Services</h2>
          <ul>
            <li>  <Link to="/Webdesign">
Web Development</Link></li>
            <li> <Link to="/Soft">
Software Development</Link></li>
            <li>  <Link to="/DigitalMarket">
Digital Marketing</Link></li>
            <li>  <Link to="/SoftTest">
Software Testing</Link></li>
            <li>  <Link to="/design">
UI/UX Design</Link></li>
           
          </ul>

        </div>
        <div className="footer-section">
          <h2 className='foot-h'>Support</h2>
          <ul>
            <li>Contact</li>
            <li>Privacy Policy</li>
            <li>Terms & Conditions</li>
          </ul>
        </div>
        <div className="footer-section">
          <h2 className='foot-h'>Get in Touch</h2>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61559691146330&mibextid=LQQJ4d"><i className='bx bxl-facebook-circle'></i></a>
            <a href="https://wa.me/918438558527"><i class='bx bxl-whatsapp'></i></a>
            <a href="https://www.instagram.com/vetriitsystems?igsh=MXBtMnJ6Z202NGppaA%3D%3D&utm_source=qr"><i className='bx bxl-instagram-alt'></i></a>
          </div>
        </div>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
