import React from 'react'
import './webdesign.css'
import web from './image/web.jpg';
import Sign from './designport';

import exd1 from './image/figma.jpg';
import exd2 from './image/adobe-illustrator.jpg';
import exd3 from './image/balsamiq.jpg';
import exd4 from './image/sketch.jpg';
import exd5 from './image/xd.jpg';
export const Design = () => {
    return (
        <div>
            <section className="web" data-aos="fade-up">
                <div className="web-container">
                    <div className="web-row">
                        <div className="web-col1">
                            <div>
                                <p className="web-title">BEST UI/UX DESIGN COMPANY</p>
                                <h1 className="web-head">Enhancing Your Business with Top-Notch UI/UX Design</h1>
                                <p className="web-para">
                                UI/UX Design will help your Business to give an Awesome Visibility to the Customers with Posters, Flyers, Brochure..
                                </p><br />
                                
                            </div>
                        </div>
                        <div className="web-col2">
                            <div className="web-img">
                                <img src={web} alt="Web Design" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
            <Sign />
    </section>
   
            <section className='we-exp'>
        <div className='we-exp-cont'>
          <h1>We Are Expertise In:</h1>
          <div className='we-exp-row'>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>
               <img src={exd1}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>

               <img src={exd2}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>

               <img src={exd3}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
                <div className='we-exp-img'>
               <img src={exd4}></img>
               </div>
               </div>
            </div>
            <div className='we-exp-col'>
               <div className='we-exp-card'>
               <div className='we-exp-img'>

               <img src={exd5}></img>
               </div>
               </div>
            </div>

           

          </div>
        </div>

</section>
        </div>
    )
};
