
import React, { useState } from 'react';
import './Portfolio.css';
import web from './image/im2.png';
import Websites from './webport';
import DigitalMarketing from './digiport';
import GraphicsDesign from './designport';


const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState('websites');
  const renderContent = () => {
    switch (selectedCategory) {
      case 'websites':
        return <Websites />;
      case 'digital-marketing':
        return <DigitalMarketing />;
      case 'graphics-design':
        return <GraphicsDesign />;
      default:
        return <Websites />;
    }
};
  return (
    <div>
      <section className="port-web">
        <div className="port-web-container">
          <div className="port-web-row">
            <div className="port-web-col1">
              <div>
                <p className="port-web-title">VIS TREASURE</p>
                <h1 className="port-web-head">Our Previous Works</h1>
                <p className="port-web-para">
                  Introducing Vetri It Systems’ Landing Page Showcase: Experience Our Engaging Designs! Witness the simplicity, effectiveness, and captivating layouts that convert visitors into enthusiastic customers. Explore our portfolio to see how we craft high-converting landing pages designed to drive your business forward.
                </p>
              </div>
            </div>
            <div className="port-web-col2">
              <div className="port-web-img">
                <img className="img-ani" src={web} alt="Portfolio" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <nav className="portfolio-nav">
        <button onClick={() => setSelectedCategory('websites')} className={selectedCategory === 'websites' ? 'active-link' : ''}>WEBSITE</button>
        <button onClick={() => setSelectedCategory('digital-marketing')} className={selectedCategory === 'digital-marketing' ? 'active-link' : ''}>DIGITAL MARKETING</button>
        <button onClick={() => setSelectedCategory('graphics-design')} className={selectedCategory === 'graphics-design' ? 'active-link' : ''}>UI/UX DESIGNS</button>
      </nav>

    <div className="portfolio-content">
        {renderContent()}
    </div>
  </div>
  );
};

export default Portfolio;
