import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Carousel.css';
import i12 from './image/software.gif';
import i13 from './image/applications.gif';
import i16 from './image/testing.gif';
import i18 from './image/medical-consultation.gif';
import i19 from './image/press-day.gif';

const slidesData = [
  { src: i12, heading: 'Web Development', description: 'Build interactive and dynamic websites.', price: '3200', path: '/webdesign' },
  { src: i13, heading: 'Software Development', description: 'Develop customized software solutions.', price: '5000', path: '/soft' },
  { src: i19, heading: 'Digital Marketing', description: 'Promote your business through digital channels.', price: '2500', path: '/DigitalMarketing' },
  { src: i12, heading: 'ui/UX Design', description: 'Build interactive and Designs.', price: '3700', path: '/design' },
  { src: i16, heading: 'Software Testing', description: 'Ensure software quality through thorough testing.', price: '3600', path: '/softtest' },
  { src: i18, heading: 'IT Consulting', description: 'Get expert advice for your IT projects.', price: '2100', path: '/itConsulting' },
  { src: i12, heading: 'Web Development', description: 'Build interactive and dynamic websites.', price: '3200', path: '/webdesign' },
  { src: i13, heading: 'Software Development', description: 'Develop customized software solutions.', price: '5000', path: '/soft' },
  { src: i19, heading: 'Digital Marketing', description: 'Promote your business through digital channels.', price: '2500', path: '/DigitalMarketing' },
  { src: i12, heading: 'ui/UX Design', description: 'Build interactive and Designs.', price: '3700', path: '/design' },
  { src: i16, heading: 'Software Testing', description: 'Ensure software quality through thorough testing.', price: '3600', path: '/softtest' },
  { src: i18, heading: 'IT Consulting', description: 'Get expert advice for your IT projects.', price: '2100', path: '/itConsulting' },
  { src: i12, heading: 'Web Development', description: 'Build interactive and dynamic websites.', price: '3200', path: '/webdesign' },
  { src: i13, heading: 'Software Development', description: 'Develop customized software solutions.', price: '5000', path: '/soft' },
  { src: i19, heading: 'Digital Marketing', description: 'Promote your business through digital channels.', price: '2500', path: '/DigitalMarketing' },
  { src: i16, heading: 'Software Testing', description: 'Ensure software quality through thorough testing.', price: '3600', path: '/softtest' },
  { src: i12, heading: 'ui/UX Design', description: 'Build interactive and Designs.', price: '3700', path: '/design' },
  { src: i18, heading: 'IT Consulting', description: 'Get expert advice for your IT projects.', price: '2100', path: '/itConsulting' },
  { src: i12, heading: 'Web Development', description: 'Build interactive and dynamic websites.', price: '3200', path: '/webdesign' },
  { src: i13, heading: 'Software Development', description: 'Develop customized software solutions.', price: '5000', path: '/soft' },
  { src: i12, heading: 'ui/UX Design', description: 'Build interactive and Designs.', price: '3700', path: '/design' },
  { src: i19, heading: 'Digital Marketing', description: 'Promote your business through digital channels.', price: '2500', path: '/DigitalMarketing' },
  { src: i16, heading: 'Software Testing', description: 'Ensure software quality through thorough testing.', price: '3600', path: '/softtest' },
  { src: i18, heading: 'IT Consulting', description: 'Get expert advice for your IT projects.', price: '2100', path: '/itConsulting' },
];

const SlideCarousel = () => {
  const trackRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const track = trackRef.current;
    const slideWidth = 350;
    const gapWidth = 10;
    const totalWidth = (slideWidth + gapWidth) * slidesData.length;
    const duplicatedTotalWidth = totalWidth * 2;
    const halfTotalWidth = duplicatedTotalWidth / 2;
    track.style.setProperty('--total-width', `${halfTotalWidth}px`);
    const baseDuration = 40;
    const baseWidth = 5000;
    const scrollDuration = (halfTotalWidth / baseWidth) * baseDuration;
    track.style.setProperty('--scroll-duration', `${scrollDuration}s`);
  }, []);

  const handleSlideClick = (path) => {
    navigate(path);
  };

  return (
    <section className="service" data-aos="fade-up">
    <p className="ser-title">360° Digital Marketing Service</p>
    <h1 className="ser-heading">Digital Marketing Solution To Help You Meet And Exceed Your Expectations</h1>
    <div className="carousel" aria-live="polite" aria-label="Image Carousel">
      <div className="carousel-track" ref={trackRef}>
        {slidesData.concat(slidesData).map((slide, index) => (
          <div className="slide" key={index} onClick={() => handleSlideClick(slide.path)}>
            <img className='slide-img1' src={slide.src} alt={slide.heading} />
            <h2 className='slide-h2'>{slide.heading}</h2>
            <p className='slide-p'>{slide.description}</p>
            <div className="price">
              <span>STARTS FROM <i className='bx bx-rupee bx-tada'></i> {slide.price}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
    </section>

  );
};

export default SlideCarousel;
