import React from 'react';
import './seo.css';
import seo from './image/seo1.jpg';

const Seo = () => {
  return (
    <div>
      <section className="seo">
        <div className="seo-container">
          <div className="seo-row">
            <div className="seo-col1" data-aos="fade-right">
              <div>
                <p className="seo-title">SEO Company In Tirunelveli/Tenkasi</p>
                <h1 className="seo-head">The Brain Behind Your Business</h1>
                <p className="seo-para">
                  Vetri Technology Solutions is the best in providing all types of SEO services. We are the premium company in the field of SEO services and work on enhancing the online visibility of businesses and brands. We provide the best SEO services in Tirunelveli/Tenkasi and cover all the areas of SEO services such as SERP rankings, SMO, SMM, PPC, Google ads and many more. All the technical staff of our expert SEO team is pro in digital marketing and know well how to improve rankings and enhance brand value.
                </p>
              </div>
            </div>
            <div className="seo-col2" data-aos="fade-left">
              <div className="seo-img">
                <img className="img-ani" src={seo} alt="SEO Services" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="seo-main">
        <div className="seo-main-container">
          <h1>Services We Offer</h1>
          <h1>Top SEO Company In Tirunelveli/Tenkasi</h1>
          <p>
            Dazzle Tech Solutions reigns as Coimbatore’s premier SEO expert, elevating businesses with comprehensive, cutting-edge strategies for unparalleled online visibility and growth.
          </p>
          <div className="seo-main-row"></div>
        </div>
      </section> */}

      <section className="seo-want" data-aos="fade-up">
        <div className="seo-want-container">
          <h1 className="seo-want-title">Seo Services In Tirunelveli/Tenkasi</h1>
          <h1 className="seo-want-head">Want your website to be optimized to get a high rank on Google SERP?</h1>
          <p className="seo-want-para">
            Our digital marketing experts and SEO analysts keep you informed about each progress and process of the SEO campaign so that you have the total knowledge about your website’s progress. Also, you can ask any question at any time.
          </p>
        </div>
      </section>

      <section className='faq' data-aos="fade-up">
        <div className="faq-container">
          <h1 className="faq-title">SEO Agency In Tirunelveli/Tenkasi</h1>
          <h1 className="faq-head">Frequently Asked Questions ( FAQ )</h1>
          <details>
            <summary>What is keyword research and why is it important?</summary>
            <div className="faq-content">
              Keyword research identifies the search terms people use. It helps target relevant traffic and improve search engine rankings.
            </div>
          </details>
          <details>
            <summary>How do backlinks affect my website's SEO?</summary>
            <div className="faq-content">
              Backlinks act as votes of confidence. High-quality backlinks improve your site's authority and search engine ranking.
            </div>
          </details>
          <details>
            <summary>What is on-page SEO?</summary>
            <div className="faq-content">
              On-page SEO involves optimizing individual web pages. This includes content, HTML source code, and user experience to rank higher.
            </div>
          </details>
          <details>
            <summary>How often should I update my website's SEO?</summary>
            <div className="faq-content">
              Regular updates are crucial. SEO should be revisited at least quarterly to adapt to algorithm changes and market trends.
            </div>
          </details>
        </div>
      </section>
    </div>
  );
};

export default Seo;
